
import { defineComponent } from 'vue';
import FormItem from '@/components/form/item.vue';

export default defineComponent({
    name: 'CompositeField',
    components: { FormItem },
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [Object], default: () => {} },
        disabled: { type: Boolean, default: false },
        fields: { type: Array, default: () => {} },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
});
