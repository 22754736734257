<template>
    <div class="input-image-builder" :class="classes">
        <div v-if="!isShowManager" class="input-image-builder__preview">
            <div class="input-image-builder__preview-img" :style="previewAspectRatio">
                <img :src="previewURI" alt="preview" />
            </div>
            <div class="input-image-builder__preview-btns">
                <div class="input-image-builder__preview-btn -upload">
                    <Button icon="upload" fullwidth @click="openImagesManager">
                        {{ $t('entities.image.change') }}
                    </Button>
                </div>
                <div v-if="value && btnReset" class="input-image-builder__preview-btn -clear">
                    <Button icon="clear" @click="clearImage"></Button>
                </div>
            </div>
        </div>
        <div v-else class="input-image-builder__preview">
            <ImageManager v-model="model" :image-type="imageType" @close="closeImagesManager" />
        </div>
    </div>
</template>

<script>
import ImageService from '@/services/ImageService';
import Button from '@/components/common/Button.vue';
import { imageUrl } from '@/helpers/values';
import { mapGetters } from 'vuex';

export default {
    name: 'InputImage',
    inheritAttrs: false,
    props: {
        name: { type: String, default: '' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Number, Object, Date] },
        aspectRatio: { type: Number, default: 19 / 9.2 },
        width: { type: String, default: '100%' },
        maxWidth: { type: String, default: '100%' },
        isPopup: { type: Boolean },
        imageType: { type: String, default: 'content' },
        note: { type: String, default: 'JPG, PNG. Recomended size: 320х432px. No more than 500kb' },
        btnReset: { type: Boolean, value: false },
    },
    components: {
        Button,
        ImageManager: () => import('@/components/form/controls/ImageManager.vue'),
    },
    created() {
        this.getImage();
    },
    data() {
        return {
            image: null,
            isShowManager: false,
            placeholder: {
                uri: require('@/assets/images/image-input-placeholder.svg'),
                width: 100,
                height: 50,
            },
        };
    },
    computed: {
        model: {
            get() {
                return this.image;
            },
            set(image) {
                this.image = image;
                const imageId = this.image.id;
                this.$emit('input', imageId);
            },
        },
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),

        previewURI() {
            if (this.image === null) return this.placeholder.uri;
            const publicUrl = `https://${this.instanceFull.primaryDomain}`;

            if (this.image.uri === publicUrl + '/helper/placeholder.svg') return this.image.uri;
            return this.image
                ? imageUrl() + '/thumbs/inset/300x300' + this.image.uri
                : require('@/assets/images/product-no-photo.png');
        },
        previewAspectRatio() {
            if (!this.image) return 'padding-bottom: 100%';
            return `padding-bottom: ${(this.image.height / this.image.width) * 100}%`;
        },
        classes() {
            return {
                // '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
                // '-disabled': this.disabled,
            };
        },
    },
    methods: {
        setPlaceholder() {
            this.image = { ...this.placeholder };
        },
        async getImage() {
            if (!this.value) {
                return;
            }

            const [error, image] = await ImageService.getOne(this.value);
            if (error) {
                error.notify();
                return;
            }
            this.image = image;
        },
        openImagesManager() {
            this.isShowManager = true;
        },
        closeImagesManager() {
            this.isShowManager = false;
        },
        clearImage() {
            this.image = null;
            this.$emit('input', this.image);
        },
    },
    watch: {
        value: function() {},
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.input-image-builder {
    &__preview {
        &-img {
            position: relative;
            height: 0;
            overflow: hidden;
            border-radius: $form-control-border-radius;
            margin-bottom: 12px;

            img {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &-btns {
            display: flex;
            gap: 8px;
            .-upload {
                flex: 1;
            }
            .-clear {
                & > .btn {
                    height: 100%;
                    padding: 0 8px;
                }
            }
        }
    }
    &__note {
        color: $disabled;
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
    }
}
</style>
