import Vue from 'vue';
import * as Sentry from '@sentry/vue';

/**
 * Плагины
 */
import router from '@/router';
import i18n from '@/plugins/i18n';
import store from '@/store/store';
import Vuelidate from 'vuelidate';
import Vuebar from 'vuebar';
import UUID from 'vue-uuid';
import '@/plugins/base';
import '@/plugins/directives';

/**
 * Фильтры
 */
import '@/helpers/filters.js';

/**
 * Компоненты
 */
import App from '@/App.vue';
import NuxtLink from '@/components/base/NuxtLink.vue';
import SimpleFileManager from '@/components/base/SimpleFileManager/SimpleFileManager.vue';

/**
 * UI Components
 */
import formControls from '@/components/form/controls';

// import CKEditor from '@ckeditor/ckeditor5-vue2';
// Vue.use(CKEditor);

/**
 * Плагины
 */
Vue.use(Vuelidate);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(UUID);

/**
 * Глобальные компоненты
 */
Vue.component('NuxtLink', NuxtLink);
Vue.component('simple-file-manager', SimpleFileManager);

/**
 * Регистрация UI компонентов
 */
Object.entries(formControls).forEach(([name, component]) => {
    Vue.component(name, component);
});

/**
 * Sentry
 */
Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENV ?? 'production',
    // enabled: process.env.NODE_ENV === 'production',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/admin.*/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
