<template>
    <div v-if="!disabled" class="select-preview-size-list">
        <Select v-model="model" :items="items" />
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Select from '@/components/form/controls/Select';

export default {
    name: 'SelectPreviewSizeList',
    components: { Select },
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        errors: { type: [Array, Object], default: () => [] },
        items: { type: [Array], default: () => [] },
        value: { type: [String, Number, Object, Date] },
        disabled: { type: Boolean, default: false },
    },
};
</script>

<style lang="scss"></style>
