export default {
    styles: {
        value: 'Styles',
        scheme: 'Scheme',
        content_width: 'Content width',
        border_radius_block: 'Radius block',
        border: 'Border',
        border_radius_btn: 'Radius button',
        border_radius_input: 'Radius input',
        fonts: 'Fonts',
        font_family_head: 'Font family head',
        font_family_body: 'Font family body',
        font_style_head: 'Font style head',
        font_style_body: 'Font style body',
        generals: 'General',
        main_background: 'Background',
        main: 'Main',
        main_headline_color: 'Headline color',
        main_content_text: 'Content text',
        main_content_link: 'Content link',
        main_content_link_hover: 'Content link hover',
        main_content_link_active: 'Content link active',
        inverted_background: 'Background',
        inverted: 'Inverted',
        inverted_headline_color: 'Headline color',
        inverted_content_text: 'Content text',
        inverted_content_link: 'Content link',
        inverted_content_link_hover: 'Content link hover',
        inverted_content_link_active: 'Content link active',
        middle_background: 'Background',
        middle: 'Middle',
        middle_headline_color: 'Headline color',
        middle_content_text: 'Content text',
        middle_content_link: 'Content link',
        middle_content_link_hover: 'Content link hover',
        middle_content_link_active: 'Content link active',
        buttons: 'Buttons',
        button_primary_background: 'Background',
        button_primary: 'Button primary',
        button_primary_background_hover: 'Background hover',
        button_primary_background_active: 'Background active',
        button_primary_background_disabled: 'Background disabled',
        button_primary_text: 'Text',
        button_primary_text_hover: 'Text hover',
        button_primary_text_active: 'Text active',
        button_secondary_background: 'Background',
        button_secondary: 'Button secondary',
        button_secondary_background_hover: 'Background hover',
        button_secondary_background_active: 'Background active',
        button_secondary_background_disabled: 'Background disabled',
        button_secondary_text: 'Text',
        button_secondary_text_hover: 'Text hover',
        button_secondary_text_active: 'Text active',
        button_outline_background: 'Background',
        button_outline: 'Button outline',
        button_outline_background_hover: 'Background hover',
        button_outline_background_active: 'Background active',
        button_outline_background_disabled: 'Background disabled',
        button_outline_text: 'Text',
        button_outline_text_hover: 'Text hover',
        button_outline_text_active: 'Text active',
        header_mobile_main_background: 'Main background',
        header_mobile: 'Header mobile',
        header_mobile_bottom_background: 'Bottom background',
        header_mobile_separator: 'Separator',
        header_mobile_main_icon: 'Main icon',
        header_mobile_bottom_icon: 'Bottom icon',
        header_mobile_bottom_text_links: 'Bottom text links',
        header_mobile_bottom_text_links_background: 'Bottom text links background',
        header_mobile_menu_background: 'Menu background',
        header_mobile_menu_lines: 'Menu lines',
        header_mobile_menu_icons: 'Menu icons',
        header_mobile_menu_text_links: 'Menu text links',
        header_mobile_menu_text_links_active: 'Menu text links active',
        header_mobile_contacts_text: 'Contacts text',
        header_desktop_main_background: 'Main background',
        header_desktop: 'Header desktop',
        header_desktop_top_background: 'Top background',
        header_desktop_bottom_background: 'Bottom background',
        header_desktop_separator_background: 'Separator background',
        header_desktop_main_icon: 'Main icon',
        header_desktop_menu_text_link: 'Menu text link',
        header_desktop_menu_text_link_hover: 'Menu text link hover',
        header_desktop_menu_text_link_active: 'Menu text link active',
        header_desktop_submenu_background: 'Submenu background',
        header_desktop_submenu_text_link: 'Submenu text link',
        header_desktop_submenu_text_link_hover: 'Submenu text link hover',
        header_desktop_submenu_text_link_active: 'Submenu text link active',
        header_desktop_menu_info_text_link: 'Menu info text link',
        header_desktop_menu_info_text_link_hover: 'Menu info text link hover',
        header_desktop_menu_info_text_link_active: 'Menu info text link active',
        header_desktop_search_background: 'Search background',
        header_desktop_search_border: 'Search border',
        header_desktop_search_text: 'Search text',
        header_desktop_search_icon: 'Search icon',
        header_desktop_search_background_hover: 'Search background hover',
        header_desktop_search_border_hover: 'Search border hover',
        header_desktop_search_text_hover: 'Search text hover',
        header_desktop_search_icon_hover: 'Search icon hover',
        footer_main_background: 'Main background',
        footer_bottom_background: 'Bottom background',
        footer_lines_background: 'Lines background',
        footer_icon_color: 'Color',
        footer_icon: 'Icon',
        footer_icon_background: 'Background',
        footer_text: 'Text',
        footer_link: 'Link',
        footer_link_hover: 'Link hover',
        footer_link_active: 'Link active',
        footer_bottom_text: 'Bottom text',
        footer_social_icon: 'Social icon',
        footer_social_icon_hover: 'Social icon hover',
        footer_social_icon_active: 'Social icon active',
        footer_menu_text_link: 'Text link',
        footer_menu: 'Footer menu',
        footer_menu_text_link_hover: 'Text link hover',
        footer_menu_text_link_active: 'Text link active',
        footer_submenu_text_link: 'Text link',
        footer_submenu: 'Footer submenu',
        footer_submenu_text_link_hover: 'Text link hover',
        footer_submenu_text_link_active: 'Text link active',
        form: 'Form',
        header: 'Header',
        footer: 'Footer',
        styles: 'Styles',
        socials: 'Socials',
        media: 'Media',
        language: 'Language',
        additional: 'Additional',
        system: 'System',
        accent: 'Accent',
        product_list: 'Product list',
        filter: 'Filter',
        paging: 'Paging',
        product_card: 'Product card',
        cart: 'Cart',
        form_input_background: 'Background',
        form_input: 'Form input',
        form_input_border: 'Border',
        form_input_border_hover: 'Border hover',
        form_input_text: 'Text',
        form_input_text_placeholder: 'Text placeholder',
        form_select_background: 'Background',
        form_select: 'Form select',
        form_select_border: 'Border',
        form_select_text: 'Text',
        form_select_icon: 'Icon',
        form_select_option_text: 'Option text',
        form_select_option_text_hover: 'Option text hover',
        form_select_option_background_hover: 'Option background hover',
        success: 'Success',
        success_bg: 'Success bg',
        warning: 'Warning',
        warning_bg: 'Warning bg',
        error: 'Error',
        error_bg: 'Error bg',
        accent1: 'Accent1',
        accent1_bg: 'Accent1 bg',
        accent2: 'Accent2',
        accent2_bg: 'Accent2 bg',
        accent3: 'Accent3',
        accent3_bg: 'Accent3 bg',
        accent4: 'Accent4',
        accent4_bg: 'Accent4 bg',
        product_list_background: 'Background',
        product_list_title: 'Title',
        product_list_description: 'Description',
        product_list_price: 'Price',
        product_list_price_discount: 'Price discount',
        product_list_reviews_start: 'Reviews start',
        product_list_reviews_text: 'Reviews text',
        product_list_availability: 'Availability',
        product_list_button_buy_background: 'Background',
        product_list_button_buy: 'Button buy',
        product_list_button_buy_background_hover: 'Background hover',
        product_list_button_buy_background_active: 'Background active',
        product_list_button_buy_background_disabled: 'Background disabled',
        product_list_button_buy_text: 'Text',
        product_list_button_buy_text_hover: 'Text hover',
        product_list_button_buy_text_active: 'Text active',
        product_list_counter_background: 'Background',
        common: 'Common',
        ecommerce: 'Ecommerce',
        mobile: 'Mobile',
        desktop: 'Desktop',
        icon: 'Icon',
        menu: 'Menu',
        submenu: 'Submenu',
        input: 'Input',
        select: 'Select',
        button_buy: 'Button "Buy"',
        counter: 'Counter',
        parameters: 'Parameters',
        options_button: 'Options button',
        options_select: 'Options select',
        button: 'Button',
        product_list_counter: 'Counter',
        product_list_counter_color: 'Color',
        product_list_counter_btn_background: 'Button background',
        product_list_counter_btn_color: 'Button color',
        product_list_counter_btn_background_hover: 'Button background hover',
        product_list_counter_btn_color_hover: 'Button color hover',
        product_list_counter_btn_background_active: 'Button background active',
        product_list_counter_btn_color_active: 'Button color active',
        product_list_parameters_background: 'Background',
        product_list_parameters: 'Parameters',
        product_list_parameters_icon_color: 'Icon color',
        product_list_parameters_icon_background: 'Icon background',
        product_list_parameters_text_color: 'Text color',
        product_list_parameters_text_border: 'Text border',
        product_list_options_button_background: 'Background',
        product_list_options_button: 'Options button',
        product_list_options_button_border: 'Border',
        product_list_options_button_text: 'Text',
        product_list_options_button_background_hover: 'Background hover',
        product_list_options_button_border_hover: 'Border hover',
        product_list_options_button_text_hover: 'Text hover',
        product_list_options_button_background_selected: 'Background selected',
        product_list_options_button_border_selected: 'Border selected',
        product_list_options_button_text_selected: 'Text selected',
        product_list_options_select_background: 'Background',
        product_list_options_select: 'Options select',
        product_list_options_select_border: 'Border',
        product_list_options_select_text: 'Text',
        product_list_options_select_icon: 'Icon',
        product_list_options_select_option_text: 'Option text',
        product_list_options_select_option_text_hover: 'Option text hover',
        product_list_options_select_option_background_hover: 'Option background hover',
        filter_desktop_background: 'Desktop background',
        filter_desktop_border: 'Desktop border',
        filter_desktop_text: 'Desktop text',
        filter_desktop_icon: 'Desktop icon',
        filter_mobile_background: 'Mobile background',
        filter_mobile_border: 'Mobile border',
        filter_mobile_text: 'Mobile text',
        filter_mobile_icon: 'Mobile icon',
        filter_button_background: 'Background',
        filter_button: 'Button',
        filter_button_background_hover: 'Background hover',
        filter_button_background_active: 'Background active',
        filter_button_background_disabled: 'Background disabled',
        filter_button_text: 'Text',
        filter_button_text_hover: 'Text hover',
        filter_button_text_active: 'Text active',
        paging_background: 'Background',
        paging_background_hover: 'Background hover',
        paging_background_active: 'Background active',
        paging_background_disabled: 'Background disabled',
        paging_text: 'Text',
        paging_text_hover: 'Text hover',
        paging_text_active: 'Text active',
        paging_text_disabled: 'Text disabled',
        product_card_background: 'Background',
        product_card_title: 'Title',
        product_card_description: 'Description',
        product_card_price: 'Price',
        product_card_price_discount: 'Price discount',
        product_card_reviews_start: 'Reviews start',
        product_card_reviews_text: 'Reviews text',
        product_card_availability: 'Availability',
        product_card_button_buy_background: 'Background',
        product_card_button_buy: 'Button buy',
        product_card_button_buy_background_hover: 'Background hover',
        product_card_button_buy_background_active: 'Background active',
        product_card_button_buy_background_disabled: 'Background disabled',
        product_card_button_buy_text: 'Text',
        product_card_button_buy_text_hover: 'Text hover',
        product_card_button_buy_text_active: 'Text active',
        product_card_counter_background: 'Background',
        product_card_counter: 'Counter',
        product_card_counter_color: 'Color',
        product_card_counter_btn_background: 'Button background',
        product_card_counter_btn_color: 'Button color',
        product_card_counter_btn_background_hover: 'Button background hover',
        product_card_counter_btn_color_hover: 'Button color hover',
        product_card_counter_btn_background_active: 'Button background active',
        product_card_counter_btn_color_active: 'Button color active',
        product_card_parameters_background: 'Background',
        product_card_parameters: 'Parameters',
        product_card_parameters_icon_color: 'Icon color',
        product_card_parameters_icon_background: 'Icon background',
        product_card_parameters_text_color: 'Text color',
        product_card_parameters_text_border: 'Text border',
        product_card_options_button_background: 'Background',
        product_card_options_button: 'Options button',
        product_card_options_button_border: 'Border',
        product_card_options_button_text: 'Text',
        product_card_options_button_background_hover: 'Background hover',
        product_card_options_button_border_hover: 'Border hover',
        product_card_options_button_text_hover: 'Text hover',
        product_card_options_button_background_selected: 'Background selected',
        product_card_options_button_border_selected: 'Border selected',
        product_card_options_button_text_selected: 'Text selected',
        product_card_options_select_background: 'Background',
        product_card_options_select: 'Options select',
        product_card_options_select_border: 'Border',
        product_card_options_select_text: 'Text',
        product_card_options_select_icon: 'Icon',
        product_card_options_select_option_text: 'Option text',
        product_card_options_select_option_text_hover: 'Option text hover',
        product_card_options_select_option_background_hover: 'Option background hover',
        cart_border: 'Border',
    },
    common: {
        value: 'Common',
        description: 'Description',
        address: 'Address',
        emails: 'Emails',
        phones: 'Phones',
        sitename: 'Site name',
        showAccount: 'Show Account',
        whatsapps: 'WhatsApps',
        workTime: 'Work time',
        privacyPolicyLink: 'Privacy Policy',
        favicon: 'Favicon',
        sharedImage: 'Shared Image',
        phoneShowOnMobileHeader: 'Show phone on mobile header',
        telegramShowOnMobileHeader: 'Show Telegram on mobile header',
        whatsappShowOnMobileHeader: 'Show Whatsapp on mobile header',
        emailShowOnMobileHeader: 'Show email on mobile header',
        phoneShowOnMobileMenu: 'Show phone on mobile menu',
        telegramShowOnMobileMenu: 'Show Telegram on mobile menu',
        whatsappShowOnMobileMenu: 'Show Whatsapp on mobile menu',
        emailShowOnMobileMenu: 'Show email on mobile menu',
        phoneShowOnWidget: 'Show phone on mobile widget',
        telegramShowOnWidget: 'Show Telegram on mobile widget',
        whatsappShowOnWidget: 'Show WhatsApp on mobile widget',
        whatsappShowOnWidgetDesktop: 'Show WhatsApp on desktop widget',
        whatsappMessage: 'Whatsapp Message',
        type: 'Site type',
        types: {
            title: 'Site type',
            ecommerce: 'Ecommerce site',
            service: 'Service site',
            serviceCart: 'Service site with cart',
        },
        phonesShowOnContactsHeader: 'Show phone on header contacts',
        whatsappShowOnContactsHeader: 'Show WhatsApp on header contacts',
        emailsShowOnContactsHeader: 'Show email on header contacts',
        telegramShowOnContactsHeader: 'Show Telegram on header contacts',
    },
    socials: {
        value: 'Social',
        facebook: 'Facebook',
        instagram: 'Instagram',
        twitter: 'X',
        linkedIn: 'Linked In',
        medium: 'Medium',
        telegram: 'Telegram',
        tiktok: 'TikTok',
        vk: 'VK',
    },
    header: {
        value: 'Header',
        template: 'Template',
        contactsSize: 'Сontacts',
        contactsTextSize: 'Сontacts text size, px',
        logo: 'Logo on Desktop',
        logoMobile: 'Logo on Mobile',
        logoAlt: 'Logo ALT',
        menu: 'Main Menu Editing',
        menuOptions: 'Main Menu options',
        menuSubOptions: 'Main Sub Menu options',
        menuInfoOptions: 'Info Menu options',
        menuOptionsTextSize: 'Text size, px',
        menuOptionsTextUppercase: 'Text uppercase',
        menuOptionsTextColor: 'Text color',
        menuInfo: 'Info Menu Editing',
        contactsStyles: 'Contacts styles',
        sendEnquiry: 'Send enquiry button',
        showSearchMobile: 'Show search on mobile',
    },
    footer: {
        value: 'Footer',
        template: 'Template',
        templates: {
            1: 'Template 1',
            2: 'Template 2',
            3: 'Template 3',
            4: 'Template 4',
        },
        background: 'Background',
        logo: 'Logo',
        logoAlt: 'Logo ALT',
        copywrite: 'Copyright',
        footerText: 'Text',
        menuOptions: 'Footer Menu options',
        menuSubOptions: 'Footer Sub Menu options',
        menuOptionsTextSize: 'Text size, px',
        menuOptionsTextUppercase: 'Text uppercase',
        menuOptionsTextColor: 'Text color',
        menuColumns: 'Menu Columns',
        menu: 'Menu Editing',
    },
    ecommerce: {
        currency: 'Currency',
        value: 'Ecommerce',
        template: 'Item Template',
        templates: {
            1: 'Template 1',
            2: 'Template 2',
            3: 'Template 3',
            4: 'Template 4',
        },
        templateListing: 'Item Listing Template',
        previewSizeInListing: 'Preview size in listing (px)',
        previewCustomSizeInListing: {
            title: 'Preview custom size in listing (px)',
            note: 'Example: 600x600; every number should be greater than 0 and lesser than 1000',
        },
        previewSizeInItem: 'Preview ratio in item',
        previewCustomSizeInItem: {
            title: 'Preview custom ratio in item',
            note: 'Example: 16x9; every number should be greater than 0 and lesser than 1000',
        },
        itemPerPage: 'Item per page',
        defaultColumns: 'Items per row',
        defaultColumnsDesktop: 'Items per row in Listing (desktop)',
        defaultColumnsTablet: 'Items per row in Listing (tablet)',
        defaultColumnsMobile: 'Items per row in Listing (mobile)',
        columnsCount: 'Columns count',
        shippingPolicy: 'Shipping Policy',
        refundPolicy: 'Refund Policy',
        imageCrop: 'Image Crop Mode',
        imageCropVariants: {
            inset:
                'Inset - Scales the image as large as possible within its container without cropping or stretching the image.',
            outbound:
                'Outbound - If the proportions of the image differ from the container, the image is cropped either vertically.',
            original: 'Original - Original image.',
        },
        skuIsShow: 'Show SKU on the site',
        showLeftSidebarInCatalog: 'Show left sidebar on catalog pages',
        showSortingInCatalog: 'Show sorting of products on catalog pages',
        showRelatedProducts: 'Show related products',
        showPriceForOutOfStock: 'Show price of products that is not in stock',
        leadsPopupTitle: 'Leads Popup Title',
        checkoutFormMessage: 'Message in the checkout form',
        checkoutOrderMessage: 'Message in the receipt',
        showLeadsPopupPolicy: 'Show policy in leads',
        leadsPopupEnabledByDefault: 'Enabled By Default',
        leadsPopupPolicyText: 'Policy text in leads',
        showCheckoutPolicy: 'Show policy on checkout form',
        checkoutPolicyEnabledByDefault: 'Enabled By Default',
        checkoutPolicyText: 'Policy text in checkout form',
        fastOrder: 'Fast order',
        showCheckoutReceiver: 'Show recipient other person',
        pageNavigationType: 'Catalog Navigation Type',
        btnLoadMore: 'Button Load More',
        productWidgetRelatedPosition: 'Related Product Position',
        productReviewsEnabled: 'Show product reviews and ratio',
        showQuantity: 'Show quantity',
        showPriceFrom: 'Show price as "From {price}"',
        priceFormatSeparator: 'Price format - separator',
        modalAgeVerification: 'Age Verification Modal',
        modalCookieAccept: 'Cookie Accept Modal',
        filter: 'Filter',
        type: 'Type',
        productDetails: 'Product Details',
        templateInProductList: 'Template in Listing',
        templateInProductCard: 'Template in Card',
    },
    seo: {
        value: 'SEO',
        googleAnalytics: 'Google Analytics (ID)',
        googleVerification: 'Google Verification code',
        robots: 'Robots.txt',
        metaPixel: 'Meta Pixel (ID)',
        yandexMetrika: 'Yandex Metrika (ID)',
        yandexVerification: 'Yandex Verification',
        callTrackingMango: 'Call Tracking Mango',
        metatags: {
            heading: 'Meta Tags Templates',
            variables: 'Available variables',
            h1: 'Headline (h1)',
            title: 'Meta Title',
            description: 'Meta Description',
            product: 'Products',
            catalog: 'Catalogs',
            page: 'Pages',
            category: 'Categories',
            article: 'Articles',
        },
        redirects: {
            value: 'Redirects',
            create: 'Create Redirect',
            fromUrl: 'From URL',
            fromFile: 'Add redirects from file',
            toUrl: 'To URL',
            toObject: 'To Object',
            file: 'File',
        },
        customCode: {
            title: 'Custom Code (JS)',
            note: 'Third-party code can greatly affect the performance and correctness of the site',
        },
        customCodeFooter: {
            title: 'Custom Code Footer',
            note: 'Third-party code can greatly affect the performance and correctness of the site',
        },
    },
    paymentCashOnDelivery: {
        value: 'Cash on delivery',
        methodName: 'Name',
    },
    paymentYooKassa: {
        value: 'YooKassa',
        enabled: 'Enabled',
        currency: 'Currency',
        returnUrl: 'Return URL',
        secret: 'Secret',
        shopId: 'ShopID',
        vatCode: 'Vat Code',
        methodName: 'Name',
    },
    paymentNetworkGenius: {
        value: 'NetworkGenius',
        reference: 'reference',
        secretKey: 'secretKey',
        headerToken: 'headerToken',
        language: 'Language',
        currency: 'Currency',
        testMode: 'TestMode',
        methodName: 'Name',
    },
    paymentStripe: {
        value: 'Stripe',
        apiKey: 'apiKey',
        currency: 'Currency',
        testMode: 'TestMode',
        methodName: 'Name',
    },
    erpFirstBit: {
        value: 'FirstBit',
        enabled: 'Enabled',
        url: 'Url',
        login: 'Login',
        password: 'Password',
    },
    bitrix: {
        value: 'Bitrix',
        enabled: 'Активно',
        domain: 'Domain',
        userId: 'User Id',
        secret: 'Secret',
    },
    notifierEmail: {
        value: 'Email Notification Settings',
        enabled: 'Enable',
        recipients: 'Recipients',
        senderEmail: 'Sender Email',
        senderName: 'Sender Name',
        addRecipient: 'Add recipients',
        logo: 'Logo for email (png)',
    },
    notifierTelegram: {
        value: 'Telegram Notification Settings',
        enabled: 'Enable',
        chatId: 'chatId',
        token: 'Token',
        template: 'Template',
        templateStandard: 'Standard Template',
        templateCustom: 'Use Custom Template',
        availableVariables: 'Available Variables',
        clientInfo: 'Client Info',
        orderInfo: 'Order Info',
        shippingAndDeliveryInfo: 'Shipping and Delivery Info',
        yourInfo: 'Shop Info',
        variables: {
            orderNumber: "Order's number",
            name: "Client's name",
            domain: 'Domain',
            email: "Client's email",
            phone: "Client's phone number",
            country: 'Country',
            region: 'Region',
            city: 'City',
            address: 'Address',
            apartment: 'Apartment',
            postCode: 'Postcode',
            deliveryOrPickupAddress: 'Delivery or pickup address',
            deliveryPrice: 'Delivery price',
            total: 'Total price',
            orderList: 'List of items in order',
            date: 'Date of order',
            location: 'Location',
            text: 'Text',
            copyright: 'Copyright policy',
            logo: "Your shop's logo",
        },
    },
    language: {
        value: 'Language',
        current: {
            title: 'Language',
            variants: {
                en: 'English',
                ru: 'Russian',
            },
        },
    },
    additional: {
        value: 'Additional',
        optionsView: 'Product options view',
    },
    media: {
        value: 'Media',
        postsBlock: 'Posts Page Template',
        itemsStyle: 'Posts List Style:',
        itemsStyleFields: {
            borderRadius: 'Border Radius',
            imageProportions: 'Image Proportions',
            showAuthor: 'Show Author',
            showDate: 'Show Date',
            showTags: 'Show Tags',
            showFirstWordsAsAnnotation: 'Show First 20 words post teaser',
            template: 'Template',
            textAlign: 'Text Align',
        },
        itemsLayout: 'Posts List Layout:',
        itemsLayoutFields: {
            listColumn: 'List Column',
            listAlign: 'List Align',
            gap: 'Gap',
        },
        pageStyle: 'Post Page Style:',
        pageStyleFields: {
            authorBlock: 'Author Block Style',
            commentsBlock: 'Comments Block Style',
            showAuthor: 'Show Author',
            showComments: 'Show Comments',
            showDate: 'Show Date',
            showTableOfContent: 'Show Table of Content',
        },
    },
};
