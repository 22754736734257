<template>
    <div class="input-array" :class="{ '-disabled': disabled }">
        <div class="input-array__item" v-for="(item, index) in values" :key="'input-array' + index">
            <input
                class="input-array__field"
                :class="{ '-has-errors': hasError(index) }"
                :disabled="disabled"
                v-model="values[index]"
                v-bind="inputProps"
                @input="handlerInput"
            />
            <Button type="icon" icon="delete" @click="removeField(index)" />
            <ul v-if="value && errors[index] && errors[index].length" class="input-array__errors">
                <li v-for="(error, index) in errors[index]" :key="index">{{ $t(error) }}</li>
            </ul>
        </div>
        <Button class="input-array__btn-add" type="text" icon="add_circle_outline" @click="addField(index)">Add</Button>
    </div>
</template>

<script>
import Button from '@/components/common/Button.vue';

export default {
    name: 'InputArray',
    inheritAttrs: false,
    components: {
        Button,
    },
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        placeholder: { type: String, default: '' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [Array, String], default: () => [] },
        disabled: { type: Boolean, default: false },
    },
    model: {
        prop: 'value',
        event: 'change-value',
    },
    data() {
        return {
            values: (() => {
                if (Array.isArray(this.value)) {
                    return this.value.reduce((accumulator, currentValue, currentIndex) => {
                        accumulator[currentIndex] = currentValue;
                        return accumulator;
                    }, {});
                } else return { 0: '' };
            })(),
        };
    },
    computed: {
        inputProps() {
            return {
                type: this.type,
                name: this.name,
                placeholder: this.placeholder,
            };
        },
    },
    methods: {
        hasError(index) {
            return this.errors[index] && this.errors[index].length;
        },
        addField() {
            this.values = { ...this.values, [Object.keys(this.values).length]: '' };
        },
        removeField(index) {
            const values = Object.entries(this.values);
            values.splice(index, 1);
            this.values = Object.fromEntries(values);
        },
        // handlerInput(e) {
        //     console.log(e);
        // },
        handlerInput() {
            // console.log(e);
        },
    },
    watch: {
        values: {
            deep: true,
            handler(newValue) {
                const values = Object.values(newValue);
                this.$emit('change-value', values);
                this.$emit('update-field', {
                    name: this.name,
                    group: this.group,
                    value: newValue,
                });
            },
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.input-array {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    gap: 12px;
    position: relative;

    &.-disabled {
        input,
        textarea {
            color: var(--v-on-surface-disabled) !important;
        }
        .input-array__btn-add {
            pointer-events: none;
            filter: grayscale(1);
        }
        .input-array__field {
            background: rgba(0, 0, 0, 0.04);
            cursor: not-allowed;
        }
    }
    &__item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        gap: 8px 8px;
    }

    &__field {
        display: block;
        flex: 1;
        height: $form-control-height;
        padding: 0 $form-control-padding-horizontal;
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        background: $form-control-bg;
        transition: $form-control-transition;
        appearance: none;
        max-width: calc(100% - 45px); //костыль, иначее иконка корзинки на windows переносится на другую строку

        &:hover,
        &:focus {
            border-color: $form-control-border-color-hover;
        }

        &::placeholder {
            color: inherit;
            opacity: 0.7;
        }

        &.-has-errors {
            border-color: $error;
        }
    }

    &__errors {
        display: block;
        width: 100%;
        color: $error;
        margin: 0;
    }

    &__btn-add {
        margin-top: -8px;
    }
}
</style>
